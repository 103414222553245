import React from 'react';
import StatusOverview from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusOverview'
import PageWrapper from '@tra-sg/gatsby-theme-c360-portal/src/components/PageWrapper';
import { Location } from "@reach/router";

export default function LabPageGallery({}) {
  return (
    <PageWrapper
      activeTab="status"
      metaTitle="System Status | c360"
    >
      <Location>
        {({ location }) => (<StatusOverview />)}
      </Location>
    </PageWrapper>
  )
}
